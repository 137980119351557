import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Container, Navbar, Nav, Image } from "react-bootstrap"
import logo_olivier_ortonne_osteopathe from "../images/logo_olivier-ortonne-osteopathe.png"

const Header = ({ siteTitle }) => (
  <header className="bg-light">
    <Container>
      <Navbar expand="md">
        <Navbar.Brand href="/">
          <Image
            src={logo_olivier_ortonne_osteopathe}
            style={{ width: "40px" }}
          ></Image>
          <span style={{ paddingLeft: "10px" }}>{siteTitle}</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarResponsive" />
        <Navbar.Collapse id="navbarResponsive">
          <Nav as="ul" className="ml-auto">
            <Nav.Item as="li">
              <Link to="/" className="nav-link" activeClassName="active">
                Accueil
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link to="/profil" className="nav-link" activeClassName="active">
                Mon Profil
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link to="/blog" className="nav-link" activeClassName="active">
                Conseils
              </Link>
            </Nav.Item>
            {/* <Nav.Item as="li">
              <Link to="/tarifs" className="nav-link" activeClassName="active">
                Tarifs
              </Link>
            </Nav.Item> */}
            {/* <Nav.Item as="li">
              <Link to="/contact" className="nav-link" activeClassName="active">
                Contact
              </Link>
            </Nav.Item> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
