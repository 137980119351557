import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHome,
  faClock,
  faEnvelopeOpen,
  faHeart,
} from "@fortawesome/free-solid-svg-icons"
import "../styles/footer.css"

const Footer = ({ siteTitle }) => (
  <div className="container-global">
    <div className="container-bloc">
      <div className="bloc">
        <div className="chapter">
          <span className="icon">
            <FontAwesomeIcon icon={faHome} size="1x" />
          </span>
          Le Cabinet
        </div>

        <div> 38 rue du nivernais</div>
        <div> 03300 CUSSET</div>
      </div>
      <div className="bloc">
        <div className="chapter">
          <span className="icon">
            <FontAwesomeIcon icon={faClock} size="1x" />
          </span>
          Nos horaires
        </div>
        <div> 8h - 12h / 14h - 20h</div>
        <div>Du lundi au vendredi</div>
      </div>
      <div className="bloc">
        <div className="chapter">
          <span className="icon">
            <FontAwesomeIcon icon={faEnvelopeOpen} size="1x" />
          </span>
          Nous contacter
        </div>
        <div> olivierortonne@hotmail.com</div>
        <div>06 50 89 79 33</div>
      </div>
    </div>
    <div className="copyright">
      <span className="copyright-space">Made with </span>
      <FontAwesomeIcon icon={faHeart} size="1x" />{" "}
      <span className="copyright-space">
        by <strong>PM</strong>
      </span>
    </div>
  </div>
)
Footer.propTypes = {
  siteTitle: PropTypes.string,
}
Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
